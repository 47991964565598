import React from 'react'
import styles from './DepositsMetrics.module.scss'
import { DepositMetric } from '../types/metrics'
import StatusCard from '../../../components/StatusCard'
import { StatusCardSkeleton, StatusCardTypes } from '../../../components/StatusCard/StatusCard'
import { useSelectStatusCard } from '../../../components/StatusCard/hook'
import { formatNumber } from '../../../utils/formatNumber'

export type DepositsMetricsProps = {
  metrics: DepositMetric[]
  fetchingMetrics: boolean
}

const DepositsMetrics: React.FC<DepositsMetricsProps> = ({ metrics, fetchingMetrics }) => {
  const [DepositsMetrics] = useSelectStatusCard<DepositMetric>({
    list: metrics,
    idListKey: 'id'
  })

  const descriptionCards = (metric: DepositMetric) => {
    if (metric.metric === mapOfMetrics.programmedQuantity) return <span>Programada + Programada (M)</span>
    if (metric.metric === mapOfMetrics.totalRealM3)
      return <span>Muestra los totales de la columna de M³ Reales en base a los datos actuales de la tabla.</span>

    if (metric.metric === mapOfMetrics.totalObjects)
      return <span>Muestra los totales de la columna de objetos en base a los datos actuales de la tabla.</span>
    return undefined
  }

  const formatTotals = (metric: DepositMetric) => {
    if (metric.metric === mapOfMetrics.totalRealM3) return formatNumber(metric.quantity, 2, true)
    return formatNumber(metric.quantity, 0, true)
  }

  return (
    <div className={styles.statusContainer}>
      {fetchingMetrics ? (
        <>
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-1'} loadingColor="#5D58F7" />
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-2'} loadingColor="#5D58F7" />
          <StatusCardSkeleton key={'StatusCardSkeleton-sales-request-3'} loadingColor="#5D58F7" />
        </>
      ) : (
        DepositsMetrics.map((metric) => {
          return (
            <StatusCard
              key={metric.id}
              type={mapOfMetricTypes[metric.id]}
              customClassname={`${styles.warningCard} ${styles[mapOfMetricsColor[metric.id]]} ${styles.fullWidth}`}
              titleCard={metric.metric}
              total={formatTotals(metric)}
              clicked={metric.clicked}
              description={descriptionCards(metric)}
              useOnlyCustomClass={true}
            />
          )
        })
      )}
    </div>
  )
}

export default DepositsMetrics

const mapOfMetricTypes: { [key: string]: StatusCardTypes } = {
  programmedQuantity: 'info',
  inTripQuantity: 'info',
  validatedQuantity: 'info',
  totalRealM3: 'info',
  totalObjects: 'info'
}

export const mapOfMetrics: { [key: string]: string } = {
  programmedQuantity: 'Ingresos programados',
  inTripQuantity: 'Ingresos en viaje',
  validatedQuantity: 'Ingresos validados',
  totalRealM3: 'Total m3 reales',
  totalObjects: 'Total objetos'
}

export const mapOfMetricsColor: { [key: string]: string } = {
  programmedQuantity: 'spaceguru',
  inTripQuantity: 'orange',
  validatedQuantity: 'green',
  totalRealM3: 'spaceguru',
  totalObjects: 'spaceguru'
}
