import { Moment } from 'moment'
import { API } from '../../../projectApi'
import { AppThunk } from '../../../store'
import { sendToastNotificationError } from '../../../utils/notifications'
import { GetDepositMetricsRequest, GetDepositMetricsSuccess, GetDepositMetricsTypes } from '../types/metrics'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { mapOfMetrics } from '../component/DepositsMetrics'
import { ProspectTypeName } from '../entities'

const depositMetricsActionsCreator = {
  getMetrics:
    ({
      dateFrom,
      dateTo,
      leadCreationDateFrom,
      leadCreationDateTo,
      statuses,
      prospectTypeName
    }: {
      dateFrom?: Moment
      dateTo?: Moment
      leadCreationDateFrom?: Moment
      leadCreationDateTo?: Moment
      statuses?: string
      prospectTypeName?: ProspectTypeName
    }): AppThunk =>
    async (dispatch) => {
      const request: GetDepositMetricsRequest = {
        type: GetDepositMetricsTypes.GET_DEPOSIT_METRICS_REQUEST
      }

      dispatch(request)
      try {
        const response = await API.TransactionHandler.Deposits.metrics({
          dateFrom,
          dateTo,
          leadCreationDateFrom,
          leadCreationDateTo,
          statuses,
          prospectTypeName
        })

        const quantityMetrics = Object.entries(response).map(([key, value]) => ({
          id: key,
          metric: mapOfMetrics[key],
          quantity: value
        }))

        const success: GetDepositMetricsSuccess = {
          type: GetDepositMetricsTypes.GET_DEPOSIT_METRICS_SUCCESS,
          payload: {
            metrics: quantityMetrics
          }
        }
        dispatch(success)
      } catch (error) {
        sendToastNotificationError('Error al obtener métricas')
        handleErrorsWithAction(error, GetDepositMetricsTypes.GET_DEPOSIT_METRICS_FAILURE, dispatch)
      }
    }
}

export default depositMetricsActionsCreator
