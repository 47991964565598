import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import calendarActionCreators from '../actions/calendar'
import transportActionCreators from '../actions/transport'
import RemovalDate, { RemovalDateProps } from '../components/RemovalDate'
import { useGetObjectsToRemove, useObjectsToRemoveTotals } from '../hooks'
import moment, { Moment } from 'moment'
import { buildCalendarArray } from '../../../components/Calendar/utils'
import { CalendarDay } from '../../../components/Calendar/calendar.types'
import { useGetCalendarLazyQuery } from '../../../projectApi/BFF/NewRemoval/getCalendar.query.generated'
import { CalculateTripCostsItemType, CalendarDateEntity, CountryId, Flag } from '../../../projectApi/BFF/gqlTypes'
import { DEFAULT_ANTICIPATION_DATE } from '../reducers/calendar'
import { sendToastNotificationError } from '../../../utils/notifications'
import { TransportModeName } from '@/common/operations/constants'

const Container = () => {
  const { calendar, transport, objects, userSearch } = useSelector((state) => state.NewRemoval)

  const dispatch = useDispatch()
  const calendarActions = bindActionCreators(calendarActionCreators, dispatch)
  const { getUnavailableDates, setAvailableDate, setSelectedCalendarDate } = calendarActions
  const transportActions = bindActionCreators(transportActionCreators, dispatch)
  const { setTransportCost } = transportActions

  const country = userSearch.user?.Country
  const { objectsToRemove, objectsMinCost } = objects
  const { userColppyInfo } = userSearch
  const { addresses, address_id, selectedTransport } = transport

  const [currentMonth, setCurrentMonth] = useState<Moment>(DEFAULT_ANTICIPATION_DATE)
  const [anticipationsDate, setAnticipationsDate] = useState<Moment>(DEFAULT_ANTICIPATION_DATE)
  const [monthGrid, setMonthGrid] = useState<CalendarDay[]>([])
  const [transportBasePrice, setTransportBasePrice] = useState(0)

  useEffect(() => {
    getUnavailableDates()
  }, [])

  const objectsToRemoveValues = useGetObjectsToRemove(objectsToRemove)
  const collectMode = selectedTransport === TransportModeName.COLLECT

  const { totalVolume } = useObjectsToRemoveTotals(
    objectsToRemoveValues,
    objectsMinCost,
    transport.cost,
    userColppyInfo?.saldo
  )

  const [getCalendar, { loading: loadingCalendar }] = useGetCalendarLazyQuery({
    onCompleted: (data) => {
      const {
        getCalendar: calendarResponse,
        calculateTripCost: { price },
        getValueByFlag: { value: anticipationDays }
      } = data

      const anticipationDate = moment().add(Number(anticipationDays), 'day')

      const populatedGrid = monthGrid.map((existingDate) => {
        const discount = findCalendarDiscount(existingDate.date, calendarResponse)
        return {
          ...existingDate,
          discount: collectMode ? undefined : discount?.discount,
          isDisabled: discount?.removalDisabled || existingDate.date.isBefore(anticipationDate)
        }
      })
      setAnticipationsDate(anticipationDate)
      setMonthGrid(populatedGrid)
      setTransportBasePrice(collectMode ? 0 : price)
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onError: () => {
      sendToastNotificationError('Hubo un error al obtener el calendario')
    }
  })

  const handleGetCalendar = () => {
    const calendar = buildCalendarArray(currentMonth)
    if (calendar.length === 0) return

    setMonthGrid(calendar)

    const firstDay = calendar.find((day) => !day.isBeforeToday && day.date.isAfter(anticipationsDate))
    const startDate = moment(firstDay?.date).format('YYYY-MM-DD')
    const lastDate = calendar[calendar.length - 1].date
    const endDate = moment(lastDate).format('YYYY-MM-DD')

    const selectedAddress = addresses.find((address) => address.ID === address_id)

    const objectsByProduct = objectsToRemoveValues.reduce<{
      [productGuid: string]: { type: CalculateTripCostsItemType; productGuid: string; amount: number }
    }>((acc, curr) => {
      if (!acc[curr.objectDetails.Product.ID]) {
        acc[curr.objectDetails.Product.ID] = {
          type: CalculateTripCostsItemType.Product,
          productGuid: curr.objectDetails.Product.ID,
          amount: 1
        }
      } else {
        acc[curr.objectDetails.Product.ID].amount += 1
      }
      return acc
    }, {})

    const variables = {
      startDate,
      endDate,
      input: {
        countryId: country as unknown as CountryId,
        m3: totalVolume,
        destination: collectMode
          ? objectsToRemoveValues[0].objectDetails.Location.Deposit.Address
          : selectedAddress?.Original ?? '',
        items: Object.values(objectsByProduct)
      },
      flagInput: {
        flag: Flag.RemovalAnticipationDays,
        countryCode: country as unknown as CountryId
      }
    }
    getCalendar({ variables })
  }

  useEffect(() => {
    handleGetCalendar()
  }, [currentMonth, addresses, address_id, objectsToRemoveValues, country, totalVolume])

  const handleChangeMonth = (direction: 'next' | 'prev') => {
    const newMonth = moment(currentMonth).add(direction === 'next' ? 1 : -1, 'month')
    setCurrentMonth(newMonth)
  }

  const handleDayPress = (day: CalendarDay) => {
    setSelectedCalendarDate(day)
    setAvailableDate(day.date)
    setTransportCost(transportBasePrice - (transportBasePrice * (day.discount || 0)) / 100)
    if (!moment(day.date).isSame(currentMonth, 'month')) {
      return setCurrentMonth(day.date)
    }
  }

  const props: RemovalDateProps = {
    selectedTimeInterval: calendar.timeIntervals.selected,
    calendarActions,
    calendarTextField: {
      currentMonth,
      localDate: calendar.selectedRemovalDate,
      anticipationsDate,
      grid: monthGrid,
      transportBasePrice,
      loading: loadingCalendar,
      handleChangeMonth,
      handleDayPress
    }
  }

  return <RemovalDate {...props} />
}

export default Container

const findCalendarDiscount = (currentDate: Moment, calendar: CalendarDateEntity[]) => {
  return calendar.find((incomingDate) => {
    return moment(incomingDate.date)
      .utc()
      .clone()
      .startOf('day')
      .isSame(moment(currentDate).clone().startOf('day'), 'day')
  })
}
