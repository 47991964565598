import types from './Deposits.types'
import { List, Map } from 'immutable'
import { combineReducers } from 'redux'
import pageReducer from './reducers/page'
import editDepositViewReducer from './reducers/EditDepositViewReducers/EditDepositView'
import serviceTypesReducer from './reducers/EditDepositViewReducers/serviceType'
import historyReducer from './reducers/depositHistory'
import calendarReducer from './reducers/EditDepositViewReducers/calendar'
import newCancelReducer from './reducers/cancel'
import cancelReducer from './CancelOld/reducer/cancel'
import aditionalsReducer from './reducers/EditDepositViewReducers/aditionals'
import transportReducer from './reducers/EditDepositViewReducers/transport'
import itemsReducer from './reducers/EditDepositViewReducers/items'
import costReducer from './reducers/EditDepositViewReducers/cost'
import addressesReducer from './reducers/EditDepositViewReducers/address'
import depositMetricsReducer from './reducers/metrics'

const LIMIT = 50

const initialRoot = Map({
  deposits: List(),
  loading: false,
  loadingReport: false,
  params: Map({
    Limit: LIMIT,
    Offset: 0,
    Column: 'id',
    Order: 'desc'
  }),
  totalRealM3: 0,
  totalObjects: 0,
  quantity: 0,
  sorter: Map({
    order: 'desc',
    field: 'id',
    columnKey: 'id'
  }),
  filter: Map({
    type: [],
    status: [],
    origin: [],
    prospect_type_name: []
  }),
  searchFilters: List([
    { key: 'id', text: '' },
    { key: 'transaction_id', text: '' },
    { key: 'user_id', text: '' },
    { key: 'operation_number', text: '' },
    { key: 'user_name', text: '' },
    { key: 'user_last_name', text: '' },
    { key: 'created_at', text: '' },
    { key: 'datetime', text: '' },
    { key: 'lead_creation_date', text: '' }
  ]),
  pagination: Map({
    current: 1,
    total: 0
  })
})

function tableReducer(state = initialRoot, action) {
  switch (action.type) {
    case types.CLEAN_FILTERS: {
      return state.merge({
        params: initialRoot.get('params'),
        filter: initialRoot.get('filter'),
        searchFilters: initialRoot.get('searchFilters'),
        pagination: initialRoot.get('pagination'),
        sorter: initialRoot.get('sorter')
      })
    }
    case types.FETCH_DEPOSITS:
      return state.merge({
        deposits: List(),
        loading: true,
        error: ''
      })
    case types.FETCH_DEPOSITS_SUCCESS:
      return state.merge({
        deposits: List(action.payload.deposits),
        totalRealM3: action.payload.totalRealM3,
        totalObjects: action.payload.totalObjects,
        loading: false,
        params: Map(action.payload.params),
        pagination: state.get('pagination').merge({
          total: action.payload.quantity
        })
      })
    case types.FETCH_DEPOSITS_FAIL:
      return state.merge({
        loading: false,
        error: action.payload.error
      })
    case types.FETCH_DEPOSITS_REPORT:
      return state.merge({
        loadingReport: true
      })
    case types.FETCH_DEPOSITS_REPORT_SUCCESS:
    case types.FETCH_DEPOSITS_REPORT_FAIL:
      return state.merge({
        loadingReport: false
      })
    case types.SET_FILTER: {
      return state.merge({
        filter: Map(action.payload.filter)
      })
    }
    case types.SET_SORTER: {
      return state.merge({
        sorter: Map(action.payload.sorter)
      })
    }
    case types.SET_PAGINATION: {
      return state.merge({
        pagination: Map(action.payload.pagination)
      })
    }
    case types.SET_SEARCH_FILTERS: {
      return state.merge({
        searchFilters: List(action.payload.searchFilters)
      })
    }
    default:
      return state
  }
}

export default combineReducers({
  table: tableReducer,
  page: pageReducer,
  cancel: newCancelReducer,
  cancelOld: cancelReducer,
  editView: editDepositViewReducer,
  serviceTypes: serviceTypesReducer,
  history: historyReducer,
  calendar: calendarReducer,
  aditionals: aditionalsReducer,
  address: addressesReducer,
  transport: transportReducer,
  items: itemsReducer,
  cost: costReducer,
  metrics: depositMetricsReducer
})
