import { AxiosResponse } from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { apiPrivate } from '../../../api'
import { CountryIdCode } from '../../../components/CountrySelector/constants/constants'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { CamelCaseOperation, Operation } from './common'

export interface Description {
  operations: Operation[]
  quantity: number
}

export interface APIResponse {
  description?: Description
  success: string
}

type Response = Omit<CamelCaseOperation, 'prospectTypeName'>

interface GetByIdParams {
  id: number
  countryCode?: CountryIdCode
}

export function getById({ id, countryCode }: GetByIdParams): Promise<Response> {
  const params = snakecaseKeys({ id, countryCode })

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/operation/deposit`, { params })
    .then((response: AxiosResponse<APIResponse>) => {
      if (response.data?.success !== 'true') throw new Error('No se pudo obtener la operación')
      if (response.data.description?.operations.length !== 1)
        throw new Error('Respuesta incorrecta al obtener la operación')

      const camelResponse = camelcaseKeys(response.data.description.operations[0], { deep: true })
      return camelResponse
    })
    .catch((err) => {
      throw err
    })
}
