import React, { useEffect, useMemo } from 'react'
import DepositsTable from '../component/DepositsTable'
import actions from '../Deposits.actions'
import newCancelActionCreators from '../actions/cancel'
import cancelActionCreators from '../CancelOld/actions/cancel'
import editViewActionsCreators from '../actions/EditDepositViewActionCreators/EditDepositView'
import { useDispatch, useSelector } from 'react-redux'
import moment, { Moment } from 'moment'
import { equals, forEachObjIndexed, isEmpty } from 'ramda'
import { addFormatFields } from '../Deposits.selectors'
import PagePanel from './page'
import NewCancelModal from './Cancel'
import CancelModal from '../CancelOld/container/cancel'
import { useEvents } from '../../../utils/eventEmitter'
import { Events } from '../../../utils/eventEmitter/events'
import { Camera, Edit, History, List, Trash } from '../../../icons'
import RemittanceModalActionCreators from '../../RemittanceModal/actionCreators'
import RemittanceModalContainer from '../../RemittanceModal/container'
import { FeatureType } from '../../featureManager/types'
import {
  DepositCustom,
  DepositParams,
  DepositsFilterCategories,
  DepositStatus,
  STATUS_OP,
  ValidEditDepositStatus
} from '../entities'
import { sendToastNotificationError } from '../../../utils/notifications'
import editDateServiceActionCreators from '../../../common/operations/editDateService/actions/editDateService'
import EditDateServiceModal from '../../../common/operations/editDateService/containers/EditDateService'
import { Permissions } from '../../UserLogged/permissions'
import { bindActionCreators } from 'redux'
import { useFeatureManager } from '../../featureManager/utils'
import { useHistory } from 'react-router'
import { usePermissions } from '../../UserLogged/hooks/usePermissions'
import { SearchFilter } from '../../../utils/searchFilterUtils'
import { CategoryFilter, Sorter } from '../../../components/DynamicTable/utils/entities'
import { NumberParam, StringParam, useQueryParams } from 'use-query-params'
import camelcaseKeys from 'camelcase-keys'
import { removeNullishValues } from '../../../components/DynamicTable/queryParamsUtils'
import snakecaseKeys from 'snakecase-keys'
import { TableActionBar } from '../../../components/TableActionBar'
import DepositsMetrics, { DepositsMetricsProps } from '../component/DepositsMetrics'
import depositMetricsActionsCreator from '../actions/metrics'
import CreateComplaintModalActions from '@/sections/Complaints/actionCreators/CreateComplaintModal'
import CreateComplaintModalContainer from '@/sections/Complaints/containers/CreateComplaintModal'
import ComplaintIcon from '@/icons/complaint'
import { StatusCardContainer } from '@/components/StatusCard/subComponents/StatusCardContainer'
import styles from '../component/DepositsMetrics.module.scss'

moment.locale('es')

const getInvalidEditDepositStatusMessage = (status: string): string => {
  switch (status) {
    case DepositStatus.CANCELLED:
      return 'Esta Operación de Ingreso ya se encuentra Cancelada'
    case DepositStatus.VALIDATED:
      return 'No se puede modificar una Operación de Ingreso Validada'
    case DepositStatus.DEPOSITED:
      return 'No se puede Modificar una Operación de Ingreso Ingresada'
    case DepositStatus.IN_TRIP:
      return 'Para Modificar una Operación de Ingreso Asignada a un Viaje debe retirar la operación del Viaje al cual fue asignado'
    case DepositStatus.ASSIGNED_TO_TRIP:
      return 'Para Modificar una Operación de Ingreso que se encuentra en Viaje debe retirar la operación del Viaje  al cual fue asignado'
    default:
      return `Solo se puede editar ingresos en los siguientes estados: ${ValidEditDepositStatus.map(
        (validEditDepositStatus) => STATUS_OP[validEditDepositStatus]
      ).join(', ')}`
  }
}

const Deposits = () => {
  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
    id: NumberParam,
    transactionId: NumberParam,
    userId: NumberParam,
    userName: StringParam,
    userLastName: StringParam,
    createdAtFrom: StringParam,
    createdAtTo: StringParam,
    transportDatetimeFrom: StringParam,
    transportDatetimeTo: StringParam,
    leadCreationDateFrom: StringParam,
    leadCreationDateTo: StringParam,
    limit: NumberParam,
    origin: StringParam,
    statuses: StringParam,
    type: StringParam,
    sortField: StringParam,
    sortDirection: StringParam,
    prospectTypeName: StringParam
  })

  const history = useHistory()
  const table = useSelector((state) => state.Deposits.table)
  const metrics = useSelector((state) => state.Deposits.metrics)
  const permissions = usePermissions()
  const formattedTableData = useMemo(
    () => ({
      deposits: addFormatFields(table.get('deposits').toArray()),
      params: table.get('params').toObject(),
      loading: table.get('loading'),
      currentSearchFilters: table.get('searchFilters').toArray(),
      currentFilter: table.get('filter').toObject(),
      currentSorter: table.get('sorter').toObject(),
      currentPagination: table.get('pagination').toObject()
    }),
    [table]
  )
  const { deposits, params, loading, currentSearchFilters, currentFilter, currentSorter, currentPagination } =
    formattedTableData

  const dispatch = useDispatch()
  const depositActions = bindActionCreators(actions, dispatch)
  const newCancelActions = bindActionCreators(newCancelActionCreators, dispatch)
  const cancelActions = bindActionCreators(cancelActionCreators, dispatch)
  const editViewActions = bindActionCreators(editViewActionsCreators, dispatch)
  const remittanceModalActions = bindActionCreators(RemittanceModalActionCreators, dispatch)
  const complaintModalActions = bindActionCreators(CreateComplaintModalActions, dispatch)
  const editDateServiceActions = bindActionCreators(editDateServiceActionCreators, dispatch)
  const { getMetrics } = bindActionCreators(depositMetricsActionsCreator, dispatch)

  const { getDeposits, setSearchFilters, setFilter, setSorter, setPagination } = depositActions

  const newCancelOperation = useFeatureManager(FeatureType.NEW_CANCEL_OPERATIONS)

  const handleGetDeposits = (params: DepositParams) => {
    getDeposits(params)
    const camelCasedParams = camelcaseKeys(params, { deep: true })
    const { column, order, ...rest } = camelCasedParams
    const newQuery = {
      ...rest,
      sortField: column,
      sortDirection: order
    }
    setQuery(removeNullishValues(newQuery), 'push')
  }

  const fetchEntryData = () => {
    handleGetDeposits(params)
    getMetrics({})
  }
  useEvents(
    [
      Events.Global.CHANGE_COUNTRY,
      Events.Deposits.DEPOSIT_CANCELLED,
      Events.Operations.DATE_SERVICE_EDITED,
      Events.Deposits.CLEAN_FILTERS
    ],
    fetchEntryData
  )

  useEffect(() => {
    const { sort_field: sortField, sort_direction: sortDirection, offset, limit, ...rest } = snakecaseKeys(query)
    const currentParams: DepositParams = {
      ...params,
      ...rest,
      limit: limit || params.Limit,
      Offset: offset || params.Offset,
      Column: sortField || params.Column,
      Order: sortDirection || params.Order
    }
    handleGetDeposits(currentParams)
    setSearchFilters([
      { key: 'id', text: String(query.id || '') },
      { key: 'transaction_id', text: String(query.transactionId || '') },
      { key: 'user_id', text: String(query.userId || '') },
      { key: 'user_name', text: query.userName || '' },
      { key: 'user_last_name', text: query.userLastName || '' },
      { key: 'created_at', text: query.createdAtFrom ? [query.createdAtFrom, query.createdAtTo] : [] },
      {
        key: 'datetime',
        text: query.transportDatetimeFrom ? [query.transportDatetimeFrom, query.transportDatetimeTo] : []
      },
      {
        key: 'lead_creation_date',
        text: query.leadCreationDateFrom ? [query.leadCreationDateFrom, query.leadCreationDateTo] : []
      }
    ])
    setFilter({
      status: query.statuses?.split(',') || [],
      origin: query.origin?.split(',') || [],
      prospect_type_name: query.prospectTypeName?.split(',') || [],
      type: query.type?.split(',') || []
    })
    setPagination({
      ...currentPagination,
      current: (query.offset || params.Offset) / (query.limit || params.Limit) + 1
    })
    setSorter({
      columnKey: query.sortField || params.Column,
      field: query.sortField || params.Column,
      order: query.sortDirection || params.Order
    })

    getMetrics(query.createdAtFrom ? { dateFrom: moment(query.createdAtFrom), dateTo: moment(query.createdAtTo) } : {})
  }, [])

  const getNewFilterParams = (
    params: DepositParams,
    filters: CategoryFilter<DepositsFilterCategories>
  ): DepositParams => {
    let filteredParams = params
    let formatFilters = {}
    const Offset = 0
    forEachObjIndexed((value, key) => {
      const keyFilter = getKeyFilter(key as string)
      filteredParams = { ...filteredParams, [keyFilter]: undefined }
      value.length && (formatFilters = { ...formatFilters, [keyFilter]: value.join() })
    }, filters)
    return { ...filteredParams, ...formatFilters, Offset }
  }

  const handleDateRangeSearch = (dataIndex: keyof DepositCustom, startDate?: Moment, endDate?: Moment) => {
    const formattedStartDate = startDate && startDate.isValid() && startDate.format('YYYY-MM-DD')
    const formattedEndDate = endDate && endDate.isValid() && endDate.format('YYYY-MM-DD')

    const searchFilters = currentSearchFilters.map((searchFilter: SearchFilter<keyof DepositCustom>) =>
      searchFilter.key === dataIndex
        ? {
            ...searchFilter,
            text: [formattedStartDate, formattedEndDate]
          }
        : searchFilter
    )
    const dateParams = {
      [`${dataIndex === 'datetime' ? 'transport_datetime' : dataIndex}_from`]: formattedStartDate,
      [`${dataIndex === 'datetime' ? 'transport_datetime' : dataIndex}_to`]: formattedEndDate
    }
    const newParams = { ...params, ...dateParams }

    setSearchFilters(searchFilters)
    setPagination({ ...currentPagination, current: 1 })
    handleGetDeposits(newParams)
    if (dataIndex === 'created_at') {
      getMetrics({
        dateFrom: startDate,
        dateTo: endDate,
        leadCreationDateFrom: query.leadCreationDateFrom ? moment(query.leadCreationDateFrom) : undefined,
        leadCreationDateTo: query.leadCreationDateTo ? moment(query.leadCreationDateTo) : undefined
      })
    }
    if (dataIndex === 'lead_creation_date') {
      getMetrics({
        leadCreationDateFrom: startDate,
        leadCreationDateTo: endDate,
        dateFrom: query.createdAtFrom ? moment(query.createdAtFrom) : undefined,
        dateTo: query.createdAtTo ? moment(query.createdAtTo) : undefined
      })
    }
  }

  const handleSearch = (dataIndex: string, text: string) => {
    const searchFilters = currentSearchFilters.map((searchFilter: SearchFilter<string>) =>
      searchFilter.key === dataIndex
        ? {
            ...searchFilter,
            text
          }
        : searchFilter
    )
    const newParams = {
      ...params,
      [dataIndex]: !isEmpty(text) ? text : undefined
    }

    setSearchFilters(searchFilters)
    setPagination({ ...currentPagination, current: 1 })
    handleGetDeposits(newParams)
  }

  const handlePagination = (newPage: number) => {
    const Offset = (newPage - 1) * params.Limit
    const newParams = { ...params, Offset }

    setPagination({ ...currentPagination, current: newPage })
    handleGetDeposits(newParams)
  }

  const handleFilter = (newFilters: CategoryFilter<DepositsFilterCategories>) => {
    if (!equals(newFilters, currentFilter)) {
      const newParams = { ...getNewFilterParams(params, newFilters) }

      setFilter(newFilters)
      setPagination({ ...currentPagination, current: 1 })
      handleGetDeposits(newParams)
      getMetrics({
        leadCreationDateFrom: query.leadCreationDateFrom ? moment(query.leadCreationDateFrom) : undefined,
        leadCreationDateTo: query.leadCreationDateFrom ? moment(query.leadCreationDateTo) : undefined,
        dateFrom: query.createdAtTo ? moment(query.createdAtFrom) : undefined,
        dateTo: query.createdAtTo ? moment(query.createdAtTo) : undefined,
        statuses: newParams.statuses,
        prospectTypeName: newParams.prospect_type_name
      })
    }
  }

  const handleSort = (newSorter: Sorter<DepositCustom>) => {
    setSorter(newSorter)
    setPagination({ ...currentPagination, current: 1 })
    handleGetDeposits({
      ...params,
      ...(newSorter.order ? { Order: newSorter.order } : {}),
      Offset: 0,
      Column: getColumn(newSorter.field)
    })
  }

  const getKeyFilter = (key: string): string => {
    switch (key) {
      case 'TransactionDescription':
        return 'type'
      case 'status':
        return 'statuses'
      case 'datetime':
        return 'transport_datetime'
      default:
        return key
    }
  }

  const getColumn = (column: string) => {
    switch (column) {
      case 'datetime':
        return 'transport_datetime'
      case 'total_storage_cost':
        return 'storage_cost'
      default:
        return column
    }
  }

  const changeRoute = ({ id }: DepositCustom) => {
    const path = `/deposits/${id}`
    history.push({
      pathname: path
    })
  }

  const editDepositView = ({ id }: DepositCustom) => {
    const path = `/editDeposit/${id}`
    history.push({
      pathname: path
    })
  }

  const viewDepositHistory = ({ id }: DepositCustom) => {
    const path = `/depositHistory/${id}`
    history.push({
      pathname: path
    })
  }

  const pagination = {
    pageSize: params.Limit,
    total: currentPagination.total,
    currentPage: currentPagination.current,
    onPageChange: handlePagination
  }

  const cancelAction = newCancelOperation
    ? {
        label: 'Cancelar ingreso',
        icon: <Trash />,
        onClick: (row: DepositCustom) => newCancelActions.openModal({ isOpen: true, operationId: row.id }),
        disabled: (row: DepositCustom | undefined) => row?.status === DepositStatus.CANCELLED
      }
    : {
        label: 'Cancelar ingreso',
        icon: <Trash />,
        onClick: (row: DepositCustom) => cancelActions.openPanel(row.operation_number, row.guid)
      }

  const editDateServiceAction = permissions.includes(Permissions.EditarFechaServicio)
    ? [
        {
          label: 'Editar fecha de servicio',
          icon: <Edit />,
          onClick: (row: DepositCustom) =>
            editDateServiceActions.openModal({
              isOpen: true,
              operationId: row.id,
              currentDateTime: row.datetime
            }),
          disabled: (row: DepositCustom | undefined) => row?.status !== DepositStatus.DEPOSITED
        }
      ]
    : []

  const menuActions = [
    {
      label: 'Ver detalles',
      icon: <List />,
      onClick: (row: DepositCustom) => changeRoute(row)
    },
    {
      label: 'Ver historial',
      icon: <History />,
      onClick: (row: DepositCustom) => viewDepositHistory(row)
    },
    ...editDateServiceAction,
    {
      label: 'Editar ingreso',
      icon: <Edit />,
      onClick: (row: DepositCustom) => {
        if (ValidEditDepositStatus.includes(row.status as DepositStatus)) {
          editViewActions.cleanStates()
          editDepositView(row)
        } else {
          sendToastNotificationError(getInvalidEditDepositStatusMessage(row.status))
        }
      }
    },
    cancelAction,
    {
      label: 'Ver remito',
      icon: <Camera />,
      onClick: (row: DepositCustom) => remittanceModalActions.setOpen(true, row.transaction_id)
    },
    {
      label: 'Crear reclamo',
      icon: <ComplaintIcon stroke="#595959" width="17" height="14" />,
      onClick: (row: DepositCustom) =>
        complaintModalActions.setOpen({
          open: true,
          operationId: row.id,
          type: 'DEPOSIT',
          userId: row.user.id
        })
    }
  ]

  const depositMetricsProps: DepositsMetricsProps = {
    metrics: metrics.metrics,
    fetchingMetrics: metrics.fetchingMetrics
  }

  const depositsTableProps = {
    operations: deposits,
    pagination,
    loading,
    searchFilters: currentSearchFilters,
    handleSearch,
    handleSort,
    handleFilter,
    handleRangePicker: handleDateRangeSearch,
    sorter: currentSorter,
    categoryFilter: currentFilter,
    history,
    actions: menuActions,
    handleDoubleClickRow: changeRoute
  }

  return (
    <div className="animated fadeIn">
      <StatusCardContainer customClassName={styles.cardContainer}>
        <DepositsMetrics {...depositMetricsProps} />
      </StatusCardContainer>

      <TableActionBar hideBorder customStyles={{ marginBottom: '40px' }}>
        <TableActionBar.Wrapper>
          <TableActionBar.ButtonAction
            toolTip
            variant="csv"
            disabled={table.get('loadingReport')}
            onClickButton={() => depositActions.getDepositsReport(params)}
          />
          <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={() => depositActions.cleanFilters()} />
        </TableActionBar.Wrapper>
      </TableActionBar>
      <DepositsTable {...depositsTableProps} />
      <PagePanel />
      <CancelModal />
      <NewCancelModal />
      <EditDateServiceModal />
      <RemittanceModalContainer />
      <CreateComplaintModalContainer />
    </div>
  )
}

export default Deposits
