import React from 'react'
import { DynamicTable } from '../../../components/DynamicTable'
import { Column, Pagination } from '../../../components/DynamicTable/types/types'
import ClientIDLinkComponent from '../../../components/ClientIDLink/ClientIDLink'
import { MenuAction } from '../../../components/actionMenu/baseMenu'
import ColorBadge from '../../../components/ColorBadge'
import {
  OPERATION_ORIGIN_FILTERS,
  OPERATION_TYPE_FILTERS,
  PROSPECT_TYPE_NAME_FILTERS,
  STATUS_FILTERS,
  STATUS_VALUES
} from '../../../common/operationsTable/constants'
import { ClosedBox, Cube, ItemsToLoad, TextFile } from '../../../icons'
import { History } from 'history'
import moment, { Moment } from 'moment'
import { DepositCustom, DepositsFilterCategories } from '../entities'
import {
  getFilterProps,
  getRangePickerProps,
  getSearchProps,
  getSortProps,
  parseDate
} from '../../../components/DynamicTable/utils/utils'
import { CategoryFilter, SearchFilter, Sorter } from '../../../components/DynamicTable/utils/entities'
import { getCurrentCountry } from '../../../components/CountrySelector/utils/utils'
import FlagCountry from '../../../components/CountrySelector/component/FlagCountry'
import { useCountries } from '../../../components/CountrySelector/hooks/hooks'
import { CopyButton } from '../../../components/CopyButton'

type DepositsTableProps = {
  operations: DepositCustom[]
  pagination: Pagination
  searchFilters: SearchFilter[]
  categoryFilter: CategoryFilter<DepositsFilterCategories>
  loading: boolean
  sorter: Sorter<DepositCustom>
  history: History
  actions: MenuAction<DepositCustom>[]
  handleSort: (newSorter: Sorter<DepositCustom>) => void
  handleSearch: (key: string, newValue: string) => void
  handleFilter: (newFilter: CategoryFilter<DepositsFilterCategories>) => void
  handleRangePicker: (key: keyof DepositCustom, startDate?: Moment, endDate?: Moment) => void
  handleDoubleClickRow: (row: DepositCustom) => void
}

const DepositsTable: React.FC<DepositsTableProps> = ({
  operations,
  searchFilters,
  categoryFilter,
  loading,
  pagination,
  sorter,
  history,
  actions,
  handleSort,
  handleSearch,
  handleFilter,
  handleRangePicker,
  handleDoubleClickRow
}) => {
  const countries = useCountries()

  const columns: Column<DepositCustom>[] = [
    {
      key: 'country_id',
      label: 'País',
      maxWidth: '30px',
      renderDataCell: ({ country_id }) => {
        const flag = getCurrentCountry(countries, country_id)

        if (!country_id) return null

        return <FlagCountry country={flag} />
      }
    },
    {
      key: 'id',
      label: 'ID',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      noPadding: true,
      search: getSearchProps('id', 'ID', handleSearch, searchFilters),
      sort: getSortProps('id', sorter, handleSort)
    },
    {
      key: 'transaction_id',
      label: 'TX',
      tooltip: 'ID Transacción',
      search: getSearchProps('transaction_id', 'ID', handleSearch, searchFilters),
      sort: getSortProps('transaction_id', sorter, handleSort),
      minWidth: 'min-content',
      maxWidth: 'min-content',
      noPadding: true
    },
    {
      key: 'user.id',
      label: 'Cliente',
      minWidth: '90px',
      renderDataCell: ({ user: { id } }) => (
        <ClientIDLinkComponent id={id} noPadding={true}>
          {id}
        </ClientIDLinkComponent>
      ),
      search: getSearchProps('user_id', 'ID Cliente', handleSearch, searchFilters)
    },

    {
      key: 'user.name',
      label: 'Nombre',
      minWidth: '104px',
      search: getSearchProps('user_name', 'nombre', handleSearch, searchFilters)
    },
    {
      key: 'user.last_name',
      label: 'Apellido',
      minWidth: '104px',
      search: getSearchProps('user_last_name', 'apellido', handleSearch, searchFilters)
    },
    {
      key: 'full_address_string',
      label: 'Dirección',
      minWidth: 'min-content',
      noPadding: true,
      renderDataCell: ({ address }) => `${address.street}, ${address.city}`
    },
    {
      key: 'copy',
      label: '',
      minWidth: 'min-content',
      maxWidth: 'min-content',
      noPadding: true,
      renderDataCell: ({ full_address_string }) => (
        <div style={{ paddingLeft: 4 }}>
          <CopyButton textToCopy={full_address_string} tooltipText="Copiado!" />
        </div>
      )
    },
    {
      key: 'created_at',
      label: 'F. de creación',
      alignment: 'left',
      minWidth: '127px',
      sort: getSortProps('created_at', sorter, handleSort),
      renderDataCell: ({ created_at }) => parseDate(created_at),
      rangePicker: getRangePickerProps('created_at', searchFilters, handleRangePicker)
    },
    {
      key: 'datetime',
      label: 'F. de servicio',
      minWidth: '127px',
      alignment: 'left',
      sort: getSortProps('datetime', sorter, handleSort),
      renderDataCell: ({ datetime }) => parseDate(datetime),
      rangePicker: getRangePickerProps('datetime', searchFilters, handleRangePicker)
    },
    {
      key: 'lead_creation_date',
      label: 'F. de creación del lead',
      alignment: 'left',
      minWidth: '127px',
      renderDataCell: ({ lead_creation_date }) =>
        lead_creation_date ? parseDate(moment(lead_creation_date).utc(), true) : '-',
      rangePicker: getRangePickerProps('lead_creation_date', searchFilters, handleRangePicker)
    },
    {
      key: 'origin',
      label: 'Origen',
      minWidth: '90px',
      filters: getFilterProps(OPERATION_ORIGIN_FILTERS, categoryFilter, 'origin', handleFilter),
      renderDataCell: ({ origin }) => (origin.indexOf('backoffice') !== -1 ? 'B.O.' : origin)
    },
    {
      key: 'prospect_type_name',
      label: 'Tipo cliente',
      filters: getFilterProps(PROSPECT_TYPE_NAME_FILTERS, categoryFilter, 'prospect_type_name', handleFilter)
    },
    {
      key: 'type',
      label: 'Tipo',
      filters: getFilterProps(OPERATION_TYPE_FILTERS, categoryFilter, 'type', handleFilter),
      sort: getSortProps('type', sorter, handleSort)
    },
    {
      key: 'cubic_m',
      label: 'M³ C',
      tooltip: 'M³ cotizados',
      minWidth: '64px',
      maxWidth: '74px',
      renderDataCell: ({ cubic_m }) => Math.round((cubic_m + Number.EPSILON) * 100) / 100
    },
    {
      key: 'cubic_real_m',
      label: 'M³ R',
      tooltip: 'M³ reales',
      minWidth: '64px',
      maxWidth: '74px',
      renderDataCell: ({ cubic_real_m }) => Math.round((cubic_real_m + Number.EPSILON) * 100) / 100
    },
    {
      key: 'items_quoted',
      label: <Cube />,
      tooltip: 'Items cotizados',
      minWidth: '64px',
      maxWidth: '74px'
    },
    {
      key: 'items_remittance',
      label: <TextFile />,
      tooltip: 'Items remito',
      minWidth: '64px',
      maxWidth: '74px'
    },
    {
      key: 'items_to_load',
      label: <ItemsToLoad />,
      tooltip: 'Objetos a cargar',
      minWidth: '64px',
      maxWidth: '74px'
    },
    {
      key: 'objects',
      tooltip: 'Objetos',
      label: <ClosedBox />,
      minWidth: '64px',
      maxWidth: '74px'
    },
    {
      key: 'status',
      label: 'Estado',
      minWidth: 'max-content',
      renderDataCell: ({ status, modify_reason, cancel_reason }) => {
        const found = STATUS_FILTERS.find((sf) => sf.value === status)
        if (!found) return status
        const props = {
          ...found.styleProps
        }

        if (
          status === STATUS_VALUES.PROGRAMMED ||
          status === STATUS_VALUES.PROGRAMMED_M ||
          (status === STATUS_VALUES.CONFIRMED && modify_reason)
        )
          return (
            <ColorBadge tooltip={modify_reason?.Description} {...props}>
              {found.text}
            </ColorBadge>
          )
        if (status === STATUS_VALUES.CANCELLED)
          return (
            <ColorBadge tooltip={cancel_reason?.Description} {...props}>
              {found.text}
            </ColorBadge>
          )
        else return <ColorBadge {...props}>{found.text}</ColorBadge>
      },
      filters: getFilterProps(STATUS_FILTERS, categoryFilter, 'status', handleFilter, true)
    }
  ]
  return (
    <DynamicTable
      columns={columns}
      loading={loading}
      hint={'Tip: Al hacer doble clic en una fila podrás ver los detalles del ingreso'}
      rows={operations}
      keyExtractor={(entity) => entity.id}
      pagination={pagination}
      actions={actions}
      onDoubleClick={handleDoubleClickRow}
      customHeightOffset={365}
    />
  )
}

export default DepositsTable
