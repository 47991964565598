import moment, { Moment } from 'moment'
import { CalendarDay } from './calendar.types'
import { CalendarDateEntity } from '../../projectApi/BFF/gqlTypes'

export const buildCalendarArray = (date: Moment): CalendarDay[] => {
  const daysThisMonth = date.daysInMonth()
  const daysInPreviousMonth = moment(date).subtract(1, 'month').daysInMonth()
  const firstDay = moment(date).startOf('month').day()
  const lastDay = moment(date).endOf('month').day()
  const calendarArray = []

  // Build days from previous month
  for (let i = 0; i < firstDay; i++) {
    const thisDate = moment(date)
      .subtract(1, 'month')
      .date(daysInPreviousMonth - firstDay + i + 1)

    calendarArray.push({
      day: daysInPreviousMonth - firstDay + i + 1,
      month: moment(date).subtract(1, 'month').month(),
      date: thisDate,
      isBeforeToday: moment(thisDate).isBefore(moment(), 'day'),
      isInSelectedMonth: false
    })
  }

  // Build days from current month
  for (let i = 1; i <= daysThisMonth; i++) {
    const day = moment(date).date(i)
    calendarArray.push({
      day: i,
      month: moment(date).month(),
      date: day,
      isBeforeToday: day.isBefore(moment(), 'day'),
      isInSelectedMonth: true
    })
  }

  const lastDayIsSunday = lastDay === 0

  // if last day is sunday, this would insert a whole week of days from next month
  // (we don't want that)
  if (!lastDayIsSunday) {
    for (let i = 0; i < 6 - lastDay; i++) {
      calendarArray.push({
        date: moment(date)
          .add(1, 'month')
          .date(i + 1),
        isBeforeToday: false,
        isInSelectedMonth: false
      })
    }
  }

  return calendarArray
}

export const splitArrayIntoChunks = <T extends CalendarDay>(arr: T[], chunkSize: number): T[][] => {
  const chunks = []
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize))
  }
  return chunks
}

export const findCalendarDiscount = (currentDate: Moment, calendar: CalendarDateEntity[]) => {
  return calendar.find((incomingDate) => {
    return moment(incomingDate.date)
      .utc()
      .clone()
      .startOf('day')
      .isSame(moment(currentDate).clone().startOf('day'), 'day')
  })
}
