import { Moment } from 'moment'
import { apiPrivate } from '../../../api'
import { AxiosResponse } from 'axios'
import { URL_BASE_TRANSACTIONS } from '../../../endpoints'
import { ProspectTypeName } from '@/sections/Deposits/entities'

type APIResponse = {
  programmed_quantity: number
  in_trip_quantity: number
  validated_quantity: number
  programmed_m_quantity: number
  total_real_m3: number
  total_objects: number
}

type Response = {
  programmedQuantity: number
  inTripQuantity: number
  validatedQuantity: number
}

type MetricsParams = {
  dateFrom?: Moment
  dateTo?: Moment
  leadCreationDateTo?: Moment
  leadCreationDateFrom?: Moment
  statuses?: string
  prospectTypeName?: ProspectTypeName
}

export function metrics({
  dateFrom,
  dateTo,
  leadCreationDateTo,
  leadCreationDateFrom,
  statuses,
  prospectTypeName
}: MetricsParams): Promise<Response> {
  const formattedDateParams = {
    date_from: dateFrom?.format('YYYY-MM-DD'),
    date_to: dateTo?.format('YYYY-MM-DD'),
    lead_creation_date_to: leadCreationDateTo?.format('YYYY-MM-DD'),
    lead_creation_date_from: leadCreationDateFrom?.format('YYYY-MM-DD'),
    statuses,
    prospect_type_name: prospectTypeName
  }

  return apiPrivate
    .get(`${URL_BASE_TRANSACTIONS}/deposits-operations/metrics`, { params: formattedDateParams })
    .then((response: AxiosResponse<APIResponse>) => {
      const data = response.data

      const formattedResponse = {
        programmedQuantity: data.programmed_quantity + data.programmed_m_quantity,
        inTripQuantity: data.in_trip_quantity,
        validatedQuantity: data.validated_quantity,
        totalRealM3: data.total_real_m3,
        totalObjects: data.total_objects
      }

      return formattedResponse
    })
    .catch((error) => {
      throw error
    })
}
