/* eslint-disable camelcase */
import { CamelCasedPropertiesDeep } from 'type-fest'
import {
  Address,
  AuditToCamel,
  CancelReason,
  EntityAuditToCamel,
  Item,
  ItemAuditToCamel,
  ModifyReason,
  Operation,
  User
} from '../../projectApi/TransactionHandler/Operation/common'

export type ProspectTypeName = 'NEW C' | 'REC C'

export type DepositParams = {
  Limit: number
  Offset: number
  Column: string
  Order: string
  country_code?: string
  transaction_id?: number
  user_id?: number
  user_name?: string
  user_last_name?: string
  creation_at_from?: string
  creation_at_to?: string
  transport_date_time_from?: string
  transport_date_time_to?: string
  lead_creation_date_from?: string
  lead_creation_date_to?: string
  origin?: string
  statuses?: string
  prospect_type_name?: ProspectTypeName
}

export type DepositsFilterCategories = 'status' | 'payment_type' | 'type' | 'origin' | 'prospect_type_name'

export enum DepositStatus {
  CONFIRMED = 'confirmed',
  CREATED = 'created',
  CANCELLED = 'cancelled',
  DEPOSITED = 'deposited',
  PROGRAMMED = 'programmed',
  PROGRAMMED_M = 'programmed_m',
  VALIDATED = 'validated',
  IN_TRIP = 'in_trip',
  ASSIGNED_TO_TRIP = 'assigned_to_trip',
  INITIAL = ''
}

export enum TransportTypesPricing {
  CUSTOM = 'custom',
  COMMON = 'common'
}

export const ValidEditDepositStatus = [DepositStatus.PROGRAMMED, DepositStatus.PROGRAMMED_M, DepositStatus.CONFIRMED]

export const STATUS_OP = {
  [DepositStatus.CONFIRMED]: 'Confirmada',
  [DepositStatus.CREATED]: 'Creada',
  [DepositStatus.CANCELLED]: 'Cancelada',
  [DepositStatus.DEPOSITED]: 'Depositada',
  [DepositStatus.PROGRAMMED]: 'Programada',
  [DepositStatus.PROGRAMMED_M]: 'Programada (M)',
  [DepositStatus.VALIDATED]: 'Validada',
  [DepositStatus.IN_TRIP]: 'En viaje',
  [DepositStatus.ASSIGNED_TO_TRIP]: 'Asignada a viaje',
  [DepositStatus.INITIAL]: ''
}

export type UserToCamel = Omit<User, 'last_name'> & {
  lastName: string
}

export type AddressToCamel = Omit<Address, 'address_id' | 'address_string' | 'postal_code'> & {
  addressId: number
  addressString: string
  postalCode: string
}

export type DepositCustom = Omit<Operation, 'discount_id' | 'status'> & {
  country_id: string
  discount_id: number | null
  items_to_load: number
  lead_creation_date: string | null
  status: string
}

export type DepositToCamel = {
  address: AddressToCamel
  cancelReason: CamelCasedPropertiesDeep<CancelReason>
  cardLastDigits: string
  cardType: string
  countryId: string
  createdAt: string
  cubicM: number
  cubicMPrice: number
  cubicRealM: number
  datetime: string
  depositId: number
  discountCode: string
  discountId: number
  fullAddressString: string
  guid: string
  id: number
  items: Item[]
  itemsQuoted: number
  itemsRemittance: number
  itemsToLoad: number
  leadCreationDate: string
  modifyReason: CamelCasedPropertiesDeep<ModifyReason>
  noTransportRequired: boolean
  objects: number
  operationNumber: number
  origin: string
  paymentType: string
  status: string
  timeInterval: string
  timeSlotId: number
  totalStorageCost: number
  transactionId: number
  transportCost: number
  tollCostInCents: number
  transportModeType: string
  type: string
  updatedAt: string
  user: UserToCamel
  validationAgent: string
}

export type ResponseOperation = Omit<DepositToCamel, 'items' | 'status'> & {
  items: Item[]
  shipmentAssistantCount: number
  shipmentAssistantPrice: number
  status: DepositStatus
  transportType: TransportTypesPricing
}

export type ItemAudit = ItemAuditToCamel

export type EntityAudit = EntityAuditToCamel

export type Audit = AuditToCamel
