import { apiPublic } from '@/api'
import { API } from '../../../projectApi'
import { EnabledFormats } from '../../../projectApi/TransactionHandler/Operation/Removals/list'
import { AppThunk } from '../../../store'
import { handleErrorsWithAction } from '../../../utils/HandleErrors'
import { setToastErrorUpdate, setToastLoading, setToastSuccessUpdate } from '../../../utils/notifications'
import { dateRangeFiltersToParams, searchFiltersToParams } from '../../../utils/searchFilterUtils'
import {
  CLEAR_STATE,
  ClearState,
  CreateAddress,
  CreateAddressRequest,
  CreateAddressSuccess,
  FetchCostEnum,
  GET_REMOVALS_FAILURE,
  GET_REMOVALS_REQUEST,
  GET_REMOVALS_SUCCESS,
  GET_REMOVAL_DETAILS_FAILURE,
  GET_REMOVAL_DETAILS_REQUEST,
  GET_REMOVAL_DETAILS_SUCCESS,
  GetAddressEnum,
  GetRemovalDetailsRequest,
  GetRemovalDetailsSuccess,
  GetRemovalReasons,
  GetRemovalReasonsRequest,
  GetRemovalReasonsSuccess,
  GetRemovalsRequest,
  GetRemovalsSuccess,
  Pagination,
  RemovalsTableCategoryFilter,
  RemovalsTableDateRangeFilter,
  RemovalsTableSearchFilter,
  RemovalsTableSort,
  SEND_CSV_FAILURE,
  SEND_CSV_REQUEST,
  SEND_CSV_SUCCESS,
  SET_VIEW_BUTTON_ACTIVE,
  SetOpenModalAddress,
  SetOpenModalAddressEnum,
  SetViewButtonActive,
  UpdateRemovalAddressEnum,
  UpdateRemovalAddressRequest,
  UpdateRemovalAddressSuccess,
  ViewTab
} from '../types/removalsTable'
import { URL_BASE_TRANSACTIONS } from '@/endpoints'
import { formatObjectsForFetchCost } from '../utils'
import { CountryIdCode } from '@/components/CountrySelector/constants/constants'
import Emitter from '@/utils/eventEmitter'
import { Events } from '@/utils/eventEmitter/events'
import actions from '@/components/Address/actions'

const RemovalsActionCreator = {
  getRemovals:
    ({
      pagination,
      sort,
      dateRangeFilters,
      categoryFilter,
      searchFilters
    }: {
      pagination: Pagination
      sort?: RemovalsTableSort
      dateRangeFilters?: RemovalsTableDateRangeFilter[]

      categoryFilter?: RemovalsTableCategoryFilter
      searchFilters?: RemovalsTableSearchFilter[]
      format?: EnabledFormats
    }): AppThunk =>
    async (dispatch) => {
      const request: GetRemovalsRequest = {
        type: GET_REMOVALS_REQUEST,
        payload: {
          newPage: pagination.page,
          pageSize: pagination.limit,
          sort,
          dateRangeFilters,
          categoryFilter,
          searchFilters
        }
      }
      dispatch(request)
      const origin = categoryFilter?.origin.join(',')
      const reasonId = categoryFilter?.removalReasons.join(',')

      try {
        const { operations, total, quantityClientsWithFullRemovals } =
          await API.TransactionHandler.Operation.Removals.list({
            limit: pagination.limit,
            offset: (pagination.page - 1) * pagination.limit,
            column: sort?.field,
            order: sort?.direction,
            ...dateRangeFiltersToParams(dateRangeFilters),
            ...searchFiltersToParams(searchFilters),
            status: categoryFilter?.status[0],

            ...(origin && { origin }),
            withMetricQuantityFullRemovals: true,
            ...(reasonId && { reasonId })
          })

        const success: GetRemovalsSuccess = {
          type: GET_REMOVALS_SUCCESS,
          payload: {
            removals: operations,
            total,
            quantityClientsWithFullRemovals
          }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_REMOVALS_FAILURE, dispatch)
      }
    },
  getRemovalDetails:
    (id: number): AppThunk =>
    async (dispatch) => {
      const request: GetRemovalDetailsRequest = {
        type: GET_REMOVAL_DETAILS_REQUEST
      }

      dispatch(request)

      try {
        const { transaction } = await API.TransactionHandler.Operation.Removals.details({ id })
        const { objects } = transaction

        const success: GetRemovalDetailsSuccess = {
          type: GET_REMOVAL_DETAILS_SUCCESS,
          payload: {
            objects
          }
        }

        dispatch(success)
      } catch (err) {
        handleErrorsWithAction(err, GET_REMOVAL_DETAILS_FAILURE, dispatch)
      }
    },
  setViewButtonActive: (viewButton: ViewTab | null): SetViewButtonActive => ({
    type: SET_VIEW_BUTTON_ACTIVE,
    payload: {
      activeViewButton: viewButton
    }
  }),
  sendReport:
    ({
      pagination,
      sort,
      dateRangeFilters,
      categoryFilter,
      searchFilters,
      format
    }: {
      pagination: Pagination
      sort?: RemovalsTableSort
      dateRangeFilters?: RemovalsTableDateRangeFilter[]
      categoryFilter?: RemovalsTableCategoryFilter
      searchFilters?: RemovalsTableSearchFilter[]
      format?: EnabledFormats
    }): AppThunk =>
    async (dispatch) => {
      const REPORT_LIMIT = 200
      const origin = categoryFilter?.origin.join(',')

      const toastId = setToastLoading('Solicitando reporte, por favor espere...')
      dispatch({ type: SEND_CSV_REQUEST })
      try {
        await API.TransactionHandler.Operation.Removals.list({
          limit: REPORT_LIMIT,
          offset: (pagination.page - 1) * pagination.limit,
          column: sort?.field,
          order: sort?.direction,
          ...dateRangeFiltersToParams(dateRangeFilters),
          ...searchFiltersToParams(searchFilters),
          status: categoryFilter?.status[0],
          ...(origin && { origin }),
          format,
          withMetricQuantityFullRemovals: true
        })
        dispatch({ type: SEND_CSV_SUCCESS })
        setToastSuccessUpdate(toastId, {
          render: `Reporte solicitado, revise su casilla de correo electrónico. (Puede tardar hasta 5 min en llegar, revise su casilla de spam)`
        })
      } catch (err) {
        setToastErrorUpdate(toastId, {
          render: 'Error al solicitar el reporte. Inténtelo nuevamente más tarde'
        })
        handleErrorsWithAction(err, SEND_CSV_FAILURE, dispatch)
      }
    },
  getRemovalReasons: (): AppThunk => async (dispatch) => {
    const request: GetRemovalReasonsRequest = {
      type: GetRemovalReasons.GET_REMOVAL_REASONS_REQUEST
    }

    dispatch(request)
    try {
      const { removalsReasons } = await API.TransactionHandler.Operation.Removals.reasons()

      const success: GetRemovalReasonsSuccess = {
        type: GetRemovalReasons.GET_REMOVAL_REASONS_SUCCESS,
        payload: { reasonsList: removalsReasons }
      }

      dispatch(success)
    } catch (error) {
      handleErrorsWithAction(error, GetRemovalReasons.GET_REMOVAL_REASONS_FAILURE, dispatch)
    }
  },
  clearState: (): ClearState => ({ type: CLEAR_STATE }),
  getAddresses:
    (userId: number): AppThunk =>
    async (dispatch) => {
      dispatch({ type: GetAddressEnum.GET_REMOVAL_ADDRESSES_REQUEST })

      try {
        const { description } = await API.UserProfile.admin.Address.getAddress(userId)
        const addresses = description

        dispatch({
          type: GetAddressEnum.GET_REMOVAL_ADDRESSES_SUCCESS,
          payload: {
            addresses
          }
        })
      } catch (error) {
        handleErrorsWithAction(error, GetAddressEnum.GET_REMOVAL_ADDRESSES_FAILURE, dispatch)
      }
    },
  setOpenModalAddress:
    ({ openModalAddress }: { openModalAddress: boolean }): AppThunk =>
    (dispatch) => {
      const setOpenModalAddress: SetOpenModalAddress = {
        type: SetOpenModalAddressEnum.SET_OPEN_MODAL_ADDRESS,
        payload: { openModalAddress }
      }
      dispatch(setOpenModalAddress)
    },
  fetchCost: (where: string, countryId: CountryIdCode): AppThunk => {
    return function (dispatch, getState) {
      dispatch({ type: FetchCostEnum.FETCH_COST_REQUEST })

      const state = getState().Removals
      const details = state.table.details
      const objectsToRemoval = formatObjectsForFetchCost(details)

      const destination = where

      const query = `?country_code=${countryId}`

      const totalM3 = details.reduce((acc, obj) => acc + obj.m3, 0)

      const body = {
        destination,
        items: objectsToRemoval,
        m3: totalM3,
        service_type_id: 'items'
      }

      return apiPublic
        .post(URL_BASE_TRANSACTIONS + '/compute/trip/cost' + query, body)
        .then((response) => {
          const priceParam = response.data.description.price
          const price = Math.ceil(parseFloat(priceParam))
          dispatch({
            type: FetchCostEnum.FETCH_COST_SUCCESS,
            payload: {
              cost: price,
              where
            }
          })
        })
        .catch((error) => {
          handleErrorsWithAction(error, FetchCostEnum.FETCH_COST_FAILURE, dispatch)
        })
    }
  },
  updateAddress:
    ({
      addressId,
      transportCost,
      removalId
    }: {
      addressId: number
      transportCost: number
      removalId: number
    }): AppThunk =>
    async (dispatch) => {
      const request: UpdateRemovalAddressRequest = {
        type: UpdateRemovalAddressEnum.UPDATE_REMOVAL_ADRESS_REQUEST
      }
      const toastId = setToastLoading('Actualizando dirección...')
      dispatch(request)

      try {
        await API.TransactionHandler.Operation.serviceAddress.update({
          addressId,
          operationId: removalId,
          transportCost
        })

        const success: UpdateRemovalAddressSuccess = {
          type: UpdateRemovalAddressEnum.UPDATE_REMOVAL_ADRESS_SUCCESS
        }

        dispatch(success)
        setToastSuccessUpdate(toastId, { render: 'Se actualizó la dirección' })
        dispatch(RemovalsActionCreator.setOpenModalAddress({ openModalAddress: false }))
        dispatch(actions.cleanAddress())
        Emitter.emit(Events.Removal.ADDRESS_CREATED)
      } catch (error) {
        setToastErrorUpdate(toastId, {
          render: 'Error al actualizar la dirección'
        })
        handleErrorsWithAction(error, UpdateRemovalAddressEnum.UPDATE_REMOVAL_ADRESS_FAILURE, dispatch)
      }
    },
  createAddress:
    (userId: number, removalId: number, transportCost: number): AppThunk =>
    async (dispatch, getState) => {
      const {
        Components: { address }
      } = getState()
      const { addressGoogle, apartment, city, floor, number, postalCode, province, street } = address.address
      const request: CreateAddressRequest = {
        type: CreateAddress.CREATE_ADDRESS_REQUEST
      }
      dispatch(request)

      try {
        const address = await API.UserProfile.admin.Address.create({
          address: street,
          apartment,
          city,
          floor,
          number,
          original: addressGoogle,
          postal_code: postalCode,
          province,
          user_id: userId
        })

        const success: CreateAddressSuccess = {
          type: CreateAddress.CREATE_ADDRESS_SUCCESS,
          payload: {
            address
          }
        }
        dispatch(success)
        dispatch(RemovalsActionCreator.updateAddress({ addressId: address.id, removalId, transportCost }))
      } catch (err) {
        handleErrorsWithAction(err, CreateAddress.CREATE_ADDRESS_FAILURE, dispatch)
      }
    }
}

export default RemovalsActionCreator
