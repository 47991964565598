import React, { useEffect } from 'react'
import CorporativeDepositsTable from '../components/depositsTable'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import CoporativeDepositListActionsCreator from '../actions/depositsTable'
import { CorporativeDepositsSearchFilter, CoporativeDepositsTableSearchKey } from '../types/depositsTable'
import { Pagination } from '../../../../utils/searchFilterUtils'
import { useEvents } from '../../../../utils/eventEmitter'
import { Events } from '../../../../utils/eventEmitter/events'
import { TableActionBar } from '../../../../components/TableActionBar'
import ABMDepositsActionsCreator from '../actions/ABMDeposits'
import ABMDepositsContainer from './ABMDeposits'
import { MenuAction } from '../../../../components/actionMenu/baseMenu'
import { Deposit } from '../../../../projectApi/TransactionHandler/Deposits/common'
import { Edit, Trash } from '../../../../icons'
import LocationsMetricsComponent from '../../Locations/components/locationsMetrics'

const CorporativeDepositsTableContainer = () => {
  const dispatch = useDispatch()

  const { getDeposits, getMetrics, resetFilters } = bindActionCreators(CoporativeDepositListActionsCreator, dispatch)
  const { setModalVisibility, setDeleteModalVisibility, setSelectedDeposit } = bindActionCreators(
    ABMDepositsActionsCreator,
    dispatch
  )

  const { deposits, fetchingDeposits, depositsMetrics, fetchingMetrics, ...tableState } = useSelector(
    (state) => state.CorporativeDepositListReducer
  )

  useEffect(() => {
    getDeposits({ pagination: tableState.pagination })
    getMetrics({ pagination: tableState.pagination })

    return () => {
      resetFilters()
    }
  }, [])

  useEvents(
    [
      Events.Global.CHANGE_COUNTRY,
      Events.CorporateDeposits.CLEAN_FILTERS,
      Events.CorporateDeposits.CREATE_DEPOSIT,
      Events.CorporateDeposits.EDIT_DEPOSIT,
      Events.CorporateDeposits.DELETE_DEPOSIT
    ],
    () => {
      getDeposits({ pagination: { ...tableState.pagination, page: 1 } })
      getMetrics({ pagination: { ...tableState.pagination, page: 1 } })
    }
  )

  const handleGetDeposits = (newParams: {
    pagination?: Pagination
    searchFilters?: CorporativeDepositsSearchFilter[]
  }) => {
    getDeposits({
      pagination: newParams.pagination || tableState.pagination,
      searchFilters: newParams.searchFilters || tableState.searchFilters
    })
  }

  const handleGetMetrics = (newParams: {
    pagination?: Pagination
    searchFilters?: CorporativeDepositsSearchFilter[]
  }) => {
    getMetrics({
      pagination: newParams.pagination || tableState.pagination,
      searchFilters: newParams.searchFilters || tableState.searchFilters
    })
  }

  const handlePageChange = (newPage: number) => {
    getDeposits({ pagination: { ...tableState.pagination, page: newPage } })
    getMetrics({ pagination: { ...tableState.pagination, page: newPage } })
  }

  const handleSearch = (key: CoporativeDepositsTableSearchKey, newValue: string) => {
    const newSearchFilters = tableState.searchFilters.map((filter) =>
      filter.key === key
        ? {
            ...filter,
            text: newValue
          }
        : filter
    )
    handleGetDeposits({ searchFilters: newSearchFilters, pagination: { ...tableState.pagination, page: 1 } })
    handleGetMetrics({ searchFilters: newSearchFilters, pagination: { ...tableState.pagination, page: 1 } })
  }

  const menuActions: MenuAction<Deposit>[] = [
    {
      label: 'Editar',
      icon: <Edit />,
      onClick: (deposit) => {
        setModalVisibility({ modalVisible: true, editMode: true })
        setSelectedDeposit(deposit)
      }
    },
    {
      label: 'Eliminar',
      icon: <Trash />,
      onClick: (deposit) => {
        setDeleteModalVisibility(true)
        setSelectedDeposit(deposit)
      }
    }
  ]

  const props = {
    fetchingDeposits,
    deposits,
    searchFilters: tableState.searchFilters,
    pagination: {
      currentPage: tableState.pagination.page,
      pageSize: tableState.pagination.pageSize,
      total: tableState.pagination.total,
      onPageChange: handlePageChange
    },
    handleSearch,
    actions: menuActions
  }

  return (
    <>
      <LocationsMetricsComponent fetchingLocationsMetrics={fetchingMetrics} locationsMetrics={depositsMetrics} />
      <TableActionBar customStyles={{ margin: '8px 0 16px 0' }}>
        <TableActionBar.ButtonAction variant="cleanFilters" onClickButton={() => resetFilters()} />
        <TableActionBar.ButtonAction
          titleButton={'Crear depósito'}
          onClickButton={() => setModalVisibility({ modalVisible: true })}
        />
      </TableActionBar>
      <ABMDepositsContainer />
      <CorporativeDepositsTable {...props} />
    </>
  )
}

export default CorporativeDepositsTableContainer
